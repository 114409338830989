import React, { ReactElement, ReactNode } from 'react';
import {
  Row,
  Container,
  Image,
  Content,
  ContentFooter,
} from './styles';
interface ILegalContent {
  data: {
    codeinjection_styles: {};
    title: string;
    html: string;
    feature_image: string;
  };
  children?: ReactNode;
}

const LegalContent = ({
  data,
  children,
}: ILegalContent): ReactElement => (
  <Row noGutters>
    <Container>
      <Content>
        <Image src={'/images/skr-logo.svg'} />
        <div dangerouslySetInnerHTML={{ __html: data.html }} />
      </Content>
      <ContentFooter>
        <Image src={'/images/logo_white.png'} />
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </ContentFooter>
    </Container>
  </Row>
);
const text = `
Copyright © 2020 Stackwalker LLC <br>
Our mailing address is: OpenIDEO, 501 The Embarcadero, Pier 28 Annex, San Francisco, California 94105
<br><br>
You're receiving this email because you have opted in through your Stackwalker.com profile or by following a Challenge.
<br><br>
Want to change how you receive these emails?
<br>
You can update your preferences or unsubscribe from this list.`;

export default LegalContent;
