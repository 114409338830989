import styled from '@emotion/styled';
import BootstrapRow from 'react-bootstrap/Row';
import BootstrapContainer from 'react-bootstrap/Container';

export const Row = styled(BootstrapRow)`
  background-color: ${({ theme }) => theme.backgroundGray};
  padding-top: 9rem;
  padding-bottom: 9rem;
`;

export const Container = styled(BootstrapContainer)`
  background-color: ${({ theme }) => theme.background};
  padding: 0;
  border: solid 1px #dedede;
  border-radius: 7px;
  box-shadow: 0 7px 40px 0 rgba(244, 244, 244, 0.5);
`;

export const Content = styled.div`
  padding: 5.5rem 5rem;
`;

export const Image = styled.img`
  margin-bottom: 3rem;
  height: 45px;
`;

export const ContentFooter = styled.div`
  background: ${({ theme }) => theme.btn.background};
  padding: 3rem 2rem;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  p {
    color: ${({ theme }) => theme.colors.text.inverted};
    font-size: ${({ theme }) => theme.fontSize.medium};
  }
`;
