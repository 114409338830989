import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from 'src/components/common/Layout';
import { MetaData } from 'src/components/common/Meta';
import LegalContent from 'src/components/LegalContent';
/**
 * Single page (/:slug)
 *
 * This file renders a single page and loads all the content.
 *
 */

interface IPage {
  data: {
    ghostPage: {
      codeinjection_styles: {};
      title: string;
      html: string;
      feature_image: string;
    };
  };
  location: {
    pathname: string;
  };
}
const Page = ({ data, location }: IPage): ReactElement => {
  const page = data.ghostPage;

  return (
    <>
      <MetaData data={data} location={location} type="website" />
      <Helmet>
        <style type="text/css">{`${page.codeinjection_styles}`}</style>
      </Helmet>
      <Layout isHome={false}>
        <LegalContent data={data.ghostPage} />
      </Layout>
    </>
  );
};

export default Page;

export const postQuery = graphql`
  query($slug: String!) {
    ghostPage(slug: { eq: $slug }) {
      ...GhostPageFields
    }
  }
`;
